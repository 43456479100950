/* General */

@font-face {
  font-family: "Inter";
  /* supporting both depricated and  future browser syntax for variable fonts */
  src: url("src/styles/fonts/Inter-VariableFont_slnt,wght.ttf")
      format("woff2-variations"),
    url("src/styles/fonts/Inter-VariableFont_slnt,wght.ttf")
      format("woff2 supports variations");
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: normal;
}

:root {
  --font-fallback: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-sans;
  --secondary: #71797e;
  --primary: #ff1c24;
  --toastify-toast-left: 2rem;
  --toastify-toast-bottom: 2rem;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  text-rendering: geometricPrecision;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", system-ui, -apple-system, sans-serif;
  overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.86);
}

h1,
h2,
h3 {
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
}

h4,
h5,
h6 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

p {
  margin: 0 0 1.6rem;
}

strong {
  font-weight: 500;
}

small {
  font-size: 1.2rem;
}

blockquote {
  padding: 1.6rem 3.2rem;
  margin: 0 0 3.2rem;

  border-left: 8px solid #eee;

  font-size: 1.6rem;
  font-style: italic;
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  text-decoration: none;
}

figure {
  margin: 0;
}
img {
  vertical-align: middle;
}

code,
pre {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}
