@tailwind base;
@tailwind components;
@tailwind utilities;
@import "general.css";

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
img {
  height: 100%;
}

input:focus::placeholder {
  color: transparent;
}

.hover-target:hover .user-tag {
  transition: background-color 0.4s ease-in-out;
  background-color: white;
  border: 1px solid #e7e7e9;
}

.secondary-text {
  color: var(--secondary);
}

.founder-resources-navitem svg {
  width: 1.25rem;
  height: 1.25rem;
}

.founder-resources-navitem:hover svg path {
  fill: var(--primary);
  transition: fill 0.2s ease-in-out;
}

.secondary-text.disabled {
  opacity: 0.5;
}

.markdown-list > p {
  margin: 0;
}
.markdown-list ol {
  list-style: lower-alpha;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 24px;
}
.markdown-list ul {
  list-style: circle;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 0px;
}
.Toastify__toast-container {
  width: -webkit-fill-available !important;
  max-width: 480px;
  padding: 0;
}
.Toastify__toast-icon {
  width: 24px;
  margin-right: 0;
}
